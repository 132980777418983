<template>
  <!-- 资料库>图标库 -->
  <div class="dict" ref="iconManager">
    <div class="form-header-btn" ref="iconHeader">
      <div class="header-title">图标库</div>
      <div>
        <el-button type="primary" v-if="isAdd" @click="handleAdd">新增</el-button>
      </div>
    </div>
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.91667vw)'"
      :autoHeight="false"
      :tableRef="'iconTable'"
      ref="iconTableList"
    >
      <el-table-column
        prop="iconName"
        label="图标名称"
        align="center"  min-width="150"
        slot="iconName"
      ></el-table-column>
      <el-table-column
        prop="scheduleIcon"
        label="图标组"
        align="center"
        slot="scheduleIcon"
      >
        <template slot-scope="scope">
          <!-- {{scope.row}} -->
          <el-image  class="preSchedule" :preview-src-list="scope.row.iconList" :src="scope.row.scheduleIcon" alt=""></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="sort"
        label="排序"
        align="center" min-width="80"
        slot="sort"
      ></el-table-column>
      <el-table-column
      min-width="100"
        prop="createDate"
        label="上传时间"
        align="center"
        slot="createDate"
      ></el-table-column>
      <el-table-column label="操作" slot="handle" align="center" min-width="150">
        <template slot-scope="scope">
          <!-- v-if="isUpdate" -->
          <el-button type="text" size="small" v-if="isUpdate" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <!--  v-if="isDelete" -->
          <el-button type="text" size="small" v-if="isDelete" @click="handleDel(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </l-table>
    <!-- 对话框 -->
    <el-dialog
      :title="title == '0' ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="38%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
        <!-- -->
      <el-form
        :model="dialogForm"
        label-width="6.25vw"
        :rules="rules"
        ref="dialogForm"
      >
        <el-form-item class="form-choose-item" prop="iconName" label="图标名称：">
          <el-input
            class="form-input"
            v-model="dialogForm.iconName"
            clearable
            :maxlength="50"
            placeholder="请输入图标名称"
          />
        </el-form-item>
        <el-form-item class="form-choose-item" prop="sort" label="排序：">
          <el-input
            class="form-input"
            v-model="dialogForm.sort"
            clearable
            type="number"
            min="0"
            placeholder="请填写排序，只支持数字"
            v-input-number  
            :maxlength="3"
            @keyup.native="getInputChange(dialogForm.sort,'sort')"
          />
        </el-form-item>
        <el-form-item class="form-choose-item" prop="scheduleIcon" label="图标组：">
          <span class="smallWord">建议尺寸：50&thinsp;px*50&thinsp;px</span>
          <div class="uploadIcon">
            <div class="iconItem">
              <div class="iconTitle">待办图标：</div>
              <div class="upload">
                <el-upload  v-if="!dialogForm.scheduleIcon"
                :http-request="onSuccessSchedule"
                :before-upload="beforeAvatarUpload"
                accept=".jpg,.png,.jpeg,.gif,.JPG,.JPEG"
                >
                  <div class="uploadBox">
                    <img src="@/assets/img/upload-icon.png" alt="">
                  </div>
                </el-upload>
                <div v-else class="iconImg">
                  <img class="delIcon" @click="delIcon(1)" src="@/assets/img/del-icon-png.png" alt="">
                  <img :src="dialogForm.scheduleIcon" alt="">
                </div>
              </div>
            </div>
            <div class="iconItem">
              <div class="iconTitle">逾期图标：</div>
              <div class="upload">
                <el-upload  v-if="!dialogForm.delayIcon"
                :before-upload="beforeAvatarUpload"
                accept=".jpg,.png,.jpeg,.gif,.JPG,.JPEG"
                :http-request="onSuccessDelay">
                    <div class="uploadBox">
                      <img src="@/assets/img/upload-icon.png" alt="">
                    </div>
                </el-upload>
                <div v-else class="iconImg">
                  <img class="delIcon" @click="delIcon(2)" src="@/assets/img/publish-fail-icon.png" alt="">
                  <img :src="dialogForm.delayIcon" alt="">
                </div>
              </div>
            </div>
            <div class="iconItem">
              <div class="iconTitle">已办图标：</div>
              <div class="upload">
                <el-upload v-if="!dialogForm.accomplishIcon"
                :before-upload="beforeAvatarUpload"
                accept=".jpg,.png,.jpeg,.gif,.JPG,.JPEG"
                :http-request="onSuccessAccomplish">
                    <div class="uploadBox">
                      <img src="@/assets/img/upload-icon.png" alt="">
                    </div>
                </el-upload>
                <div v-else class="iconImg">
                  <img class="delIcon" @click="delIcon(3)" src="@/assets/img/publish-fail-icon.png" alt="">
                  <img :src="dialogForm.accomplishIcon" alt="">
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose" class="reset">取消</el-button>
        <el-button type="primary" v-if="title == '0'" :loading="editLoading" :disabled="editLoading" @click="dialogAdd('dialogForm')">新增</el-button>
        <el-button type="primary" :loading="editLoading" :disabled="editLoading" v-else @click="handleEditBtn('dialogForm')">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    var checkOtherIcon = (rule, value, callback) => {
      if (!value) {
        return callback();
      }else
      if(this.dialogForm.delayIcon == ''){
        callback(new Error("请选择逾期图标"));
      }else if(this.dialogForm.accomplishIcon == ''){
        callback(new Error("请选择已办图标"));
      }else{
        callback(); 
      }
    };
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持大于或等于0的数字"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      tableLoading: false, // tableloading
      tableHeight: 50,
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "	图标名称",
          slotName: "iconName",
        },
        {
          label: "图标组",
          slotName: "scheduleIcon",
        },
        {
          label: "排序",
          slotName: "sort",
        },
        {
          label: "上传时间",
          slotName: "createDate",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "",
      form: {
        keyword: "",
        options: [
          {
            label: "异常",
            value: "0",
          },
          {
            label: "正常",
            value: "1",
          },
        ],
      },
      dialogForm: {
        iconName: "",
        accomplishIcon: "",
        delayIcon: "",
        scheduleIcon: "",
        sort: 0,
      },
      tableData: [],
      //   pagination: {
      //     //分页信息
      //     total: 30, // 总页数
      //     currentPage: 1, // 当前页数
      //     Size: 10, // 每页显示多少条
      //   },
      // 对话框表单校验
      rules: {
        iconName: [
          {
            required: true,
            message: "请输入图标名称",
            trigger: "blur",
          },
        ],
        accomplishIcon: [
          {
            required: true,
            message: "请选择已办图标",
            trigger: "blur",
          },
        ],
        delayIcon: [
          {
            required: true,
            message: "请选择逾期图标",
            trigger: "blur",
          },
        ],
        scheduleIcon: [
          {
            required: true,
            message: "请选择待办图标",
            trigger: "blur",
          },
          { validator: checkOtherIcon, trigger: "blur" }
        ],
        sort:[
          { validator: checkNum, trigger: "blur" }
        ]
        // sort: [
        //   {
        //     required: true,
        //     message: "请填写排序，只支持数字",
        //     trigger: "blur",
        //   },
        //   { validator: checkNum, trigger: "blur" },
        // ],
      },
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      editLoading:false,
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("iconLibrary:edit") != -1;
          this.isAdd = val.indexOf("iconLibrary:add") != -1;
          this.isDelete = val.indexOf("iconLibrary:del") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.init();
    // menulist一直在时这个方法不可缺少

    this.getPermissionButton(this.$route.path, (data) => {
      console.log(data, "右侧权限按钮按钮按钮按钮啊created");
      this.permissionButtonList = data;
    });
  },
  mounted(){
    window.onresize = (() => {
      this.tableHeight = parseInt((this.tableData.length + 1) * (66 / 1080 * window.innerHeight));
      this.$refs.iconTableList.$refs.iconTable.doLayout()
    })
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      if (this.form.keyword == "") {
        this.form.keyword = null;
      }
      // this.page.num = 1;
      this.tableLoading = true;
      this.$api
        .getIconList(
          Object.assign({
            current: this.page.num,
            size: this.page.size,
            // keyword: this.form.keyword,
          })
        )
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == "0") {
            res.data.data.records.forEach(e => {
              e.iconList = [e.scheduleIcon,e.accomplishIcon,e.delayIcon]
            });
            this.tableData = res.data.data.records;
            console.log(window)
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
            this.$nextTick(() => {
              this.tableHeight = parseInt((res.data.data.records.length + 1) * (66 / 1080 * window.innerHeight));
              console.log(this.$refs.iconTableList.$refs.iconTable)
            })
          } else {
            this.tableData = [];
            this.page.total = 0;
            this.pageFlag = false;
            this.$message.error(res.data.msg);
          }
        });
    },
    /** 表单-新增 */
    handleAdd() {
      this.dialogVisible = true;
      this.title = "0";
      // this.dialogForm = {
      //   sort: "",
      // };
    },
    /** 对话框-新增 */
    dialogAdd(dialogForm) {
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.editLoading = true
          this.$api.addIcon(this.dialogForm).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("添加成功");
              this.init();
              this.handleClose();
            } else {
              this.$message.error(res.data.msg);
            }
            this.editLoading = false
          }).catch(error=>{
            this.editLoading = false
          });
        }
      });
    },
    /** 对话框-修改提交 */
    handleEditBtn(dialogForm) {
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.editLoading = true
          this.$api.addIcon(this.dialogForm).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("修改成功");
              this.dialogVisible = false;
              this.init();
              this.handleClose();
            } else {
              this.$message.error(res.data.msg);
            }
            this.editLoading = false
          }).catch(error=>{
            this.editLoading = false
          });
        }
      });
    },
    //改变输入按键事件
    getInputChange(value,type){
      let resultValue = value.replace(/[^\w\.\/]/ig,'')
      this.dialogForm[type] = resultValue
    },
    // handleExceed 上传图标
    handleExceed(file){},
    onSuccessSchedule(file){
      var fileData = new FormData();
      // console.log(file)
      fileData.append('file', file.file);
      this.$api.uploadIconImg(fileData).then(res=>{
        if(res.data.code == '0'){
          this.dialogForm.scheduleIcon = res.data.data
          this.$message.success("上传成功");
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(error=>{})
    },
    onSuccessDelay(file){
      var fileData = new FormData();
      fileData.append('file', file.file);
      this.$api.uploadIconImg(fileData).then(res=>{
        if(res.data.code == '0'){
          this.dialogForm.delayIcon = res.data.data
          this.$message.success("上传成功");
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(error=>{})
    },
    onSuccessAccomplish(file){
      var fileData = new FormData();
      fileData.append('file', file.file);
      this.$api.uploadIconImg(fileData).then(res=>{
        if(res.data.code == '0'){
          this.dialogForm.accomplishIcon = res.data.data
          this.$message.success("上传成功");
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(error=>{})
    },
    beforeAvatarUpload(file){
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.$message.error('上传正确格式的图片!');
      }
      // const isJPG = file.type === 'image/jpeg';
    },
    // 删除图标
    delIcon(type){
      if(type == 1){
        // 待办图标
        this.dialogForm.scheduleIcon = ''
      }else if(type == 2){
        // 逾期图标
        this.dialogForm.delayIcon = ''
      }else if(type == 3){
        // 已办图标
        this.dialogForm.accomplishIcon = ''
      }
    },
    /** 对话框-关闭事件 */
    handleClose() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.dialogForm = {
          iconName: "",
          accomplishIcon: "",
          delayIcon: "",
          scheduleIcon: "",
          sort: 0,
        };
        this.$refs["dialogForm"].clearValidate();
      });
    },
    /** 行内-编辑 */
    handleEdit(row) {
      this.dialogForm = {
        iconName: row.iconName,
        accomplishIcon: row.accomplishIcon,
        delayIcon: row.delayIcon,
        scheduleIcon: row.scheduleIcon,
        sort: row.sort,
        id: row.id,
      };
      this.title = "1";
      this.dialogVisible = true;
    },
    /** 行内-删除 */
    handleDel(row) {
      // `确定要删除该条数据吗?`
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h(
            "span",
            {
              style:
                "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
            },
            "确定要删除该条数据？"
          ),
          h(
            "div",
            {
              style:
                "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
            },
            "是否确定删除该条数据，删除后，将无法恢复。"
          ),
        ]),
        "操作确认",
        {
          type: "warning",
          iconClass: "el-icon-question",
          closeOnClickModal: false,
          // customClass: "warningMessage",
          customClass: 'addChild',
        }
      ).then(() => {
        this.$api.delIcon(row.id).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            // this.page.num = 1;
            this.init();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
  },
  beforeDestroy(){
    window.onresize = null
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .tableList {
    height: calc(100% - 25px);
  }
  .smallWord{
    font-size: 14px;
    color: #8C94A7;
  }
  .uploadIcon{
    display: flex;
    width: 100%;
    .iconItem:nth-child(2){
      margin-left: 42px;
    }
    .iconItem:last-child{
      margin-left: 42px;
    }
    // :nth-child(2){
    //   margin-left: 50px;
    // }
    // justify-content: space-between;
    .uploadBox{
      border-radius: 4px;
      border: 1px dashed #B5BDCF;
      background: #F5F7FA;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .iconImg{
      position: relative;
        width: 30px;
        height: 30px;
      img{
        width: 100%;
        height: 100%;
      }
      .delIcon{
        position: absolute;
        top: -8px;
        right: -8px;
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }
  }
  /deep/.preSchedule{
    cursor: pointer;
    img{
      width: 30px ;
      height: 30px;
    }
  }
}
/deep/ .el-upload-list__item-name{
  display: none;
}
/deep/.el-table td{
  // padding: 5px 0;
}
</style>
<style>
.addChild .el-button{
  width: 96px;
  /* height: 38px; */
}
</style>